<template>
  <v-row v-if="reservationDone" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{
              already_done
                ? $t("inscriptions.already_done")
                : $tc("inscriptions.customer_studio")
            }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="$store.getters['auth/isLoggedIn']">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'appointmentList' })"
          >{{ $tc("appointments.go", 2) }}</v-btn
        >
      </v-row>
      <v-row justify="center" v-else>
        <v-btn
          style="height: 30px"
          outlined
          @click="
            $router.push({
              name: 'appointmentViewOutsite',
              params: {
                cliente_id: appointment.customer_id,
                id: appointment.id,
              },
            })
          "
          >{{ $tc("appointments.see", 2) }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <studio-card v-else>
    <template slot-scope="{}">
      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
          <v-spacer />
          <v-stepper-step step="1" :complete="e1 > 1"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("appointments.info_cliente") }}
            </div></v-stepper-step
          >

          <v-spacer />

          <!--<v-divider></v-divider>

          <v-stepper-step step="2" :complete="e1 > 2"
            >Paga y señal</v-stepper-step
          >-->
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <customer-info :appointment="appointment" @validated="save">
              <div
                class="ml-3"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'color: var(--v-primary-base); margin-top: 0px'
                    : 'color: var(--v-primary-base); margin-top: -20px'
                "
              >
                <v-checkbox
                  v-model="selected"
                  v-validate="'required|is:true'"
                  true-value="true"
                  data-vv-name="share"
                  false-value="false"
                  :label="$t('appointments.check_consent')"
                  :error-messages="errors.collect('customer-info-form.share')"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  v-bind:class="[{ 'error--text': error_check }]"
                  @input="error_check = false"
                >
                  <template v-slot:label>
                    <div
                      style="margin-top: 6px"
                      v-bind:class="[{ 'error--text': error_check }]"
                    >
                      {{ $t("check_condicions") }}
                      <a
                        @click.stop
                        target="blank"
                        :href="studio.value == 'link' ? link.value : URL"
                      >
                        {{ $t("check_condicions_links") }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </customer-info>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
    CustomerInfo: () =>
      import("@/components/inscription/customer/CustomerInfo"),
    Payment: () => import("@/components/inscription/customer/Payment"),
  },
  mounted() {
    this.fetchAppointment();
  },
  data() {
    return {
      reservationDone: false,
      already_done: false,
      e1: 1,
      selected: false,
      error_check: false,
    };
  },
  computed: {
    ...mapState("inscriptions", ["customerState"]),
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    studio() {
      let policy = this.appointment.studio.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(policy);

      return policy ? policy : "none";
    },
    link() {
      let policy = this.appointment.studio.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.$route.params.token}`;
    },
  },
  methods: {
    ...mapActions("inscriptions", ["getAppointmentById"]),
    fetchAppointment() {
      this.getAppointmentById(this.$route.params.id).then((response) => {
        //console.log(response.status);
        this.appointment = response;
        if (
          response.status !== "pending_inscription_tattooer" &&
          response.status !== "pending_inscription_studio"
        ) {
          this.reservationDone = true;
          this.already_done = true;
          return;
        }
        let customer = {
          ...this.appointment.customer,
          description: this.appointment.description,
          files: [],
          studio: this.appointment.studio,
        };
        this.$store.commit("inscriptions/SET_CUSTOMER", customer);
        this.$store.commit("inscriptions/SET_STUDIO", this.appointment.studio);
      });
    },
    ...mapActions("inscriptions", ["updateInscription"]),
    save() {
      //console.log(this.$store.state.inscriptions.customerState);
      if (!this.selected) {
        this.error_check = true;
        return;
      } else this.error_check = false;
      this.updateInscription(this.$route.params.id).then((data) => {
        //console.log(data);
        this.reservationDone = true;
      });
    },
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.save();
        }
      });
    },
  },
};
</script>