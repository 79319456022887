var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservationDone)?_c('v-row',{staticClass:"final",staticStyle:{"overflow":"hidden"}},[_c('v-img',{attrs:{"height":"900px","src":require("@/assets/background.jpg")}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top:8%'
          : _vm.$vuetify.breakpoint.lgAndDown
          ? 'margin-top: 5%'
          : ''),attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/logo.png")}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"4","src":"@/assets/background.jpg"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","color":"white !important","font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.already_done ? _vm.$t("inscriptions.already_done") : _vm.$tc("inscriptions.customer_studio"))),_c('br')])])],1),(_vm.$store.getters['auth/isLoggedIn'])?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'appointmentList' })}}},[_vm._v(_vm._s(_vm.$tc("appointments.go", 2)))])],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
            name: 'appointmentViewOutsite',
            params: {
              cliente_id: _vm.appointment.customer_id,
              id: _vm.appointment.id,
            },
          })}}},[_vm._v(_vm._s(_vm.$tc("appointments.see", 2)))])],1)],1)],1):_c('studio-card',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-spacer'),_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.e1 > 1}},[_c('div',{staticStyle:{"text-align":"center","color":"var(--v-primary-base)","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("appointments.info_cliente"))+" ")])]),_c('v-spacer')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('customer-info',{attrs:{"appointment":_vm.appointment},on:{"validated":_vm.save}},[_c('div',{staticClass:"ml-3",style:(_vm.$vuetify.breakpoint.smAndDown
                  ? 'color: var(--v-primary-base); margin-top: 0px'
                  : 'color: var(--v-primary-base); margin-top: -20px')},[_c('v-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:('required|is:true'),expression:"'required|is:true'"}],class:[{ 'error--text': _vm.error_check }],attrs:{"true-value":"true","data-vv-name":"share","false-value":"false","label":_vm.$t('appointments.check_consent'),"error-messages":_vm.errors.collect('customer-info-form.share'),"on-icon":"mdi-check-circle","off-icon":"mdi-circle-outline"},on:{"input":function($event){_vm.error_check = false}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{class:[{ 'error--text': _vm.error_check }],staticStyle:{"margin-top":"6px"}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions"))+" "),_c('a',{attrs:{"target":"blank","href":_vm.studio.value == 'link' ? _vm.link.value : _vm.URL},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions_links"))+" ")])])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }